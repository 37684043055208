import { faArrowUp19, faArrowUpAZ, faSquareA, faSquareB, faSquareD, faSquareE, faSquareF, faSquareG, faSquareH, faSquareI, faSquareJ, faSquareK, faSquareL, faSquareM, faSquareN, faSquareO, faSquareP, faSquareR, faSquareS, faSquareT, faSquareU, faSquareV, faSquareY, type IconDefinition } from '@fortawesome/pro-light-svg-icons'
import { type InferOutput, picklist } from 'valibot'

export const LAW_VARIANTS = [
  'title', // law
  'chapter', // kapittel
  'paragraph', // paragraf
  'part', // ledd
  'del', // part
  'note', // merknad
] as const

export type LawVariant = typeof LAW_VARIANTS[number]

export const alphabetSchema = picklist([
  'A',
  'B',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'R',
  'S',
  'T',
  'U',
  'V',
  'Y',
  'Å',
])

export type Alphabet = InferOutput<typeof alphabetSchema>

export const ALPHABET_OPTIONS = [
  { label: 'A', value: 'A', icon: faSquareA },
  { label: 'B', value: 'B', icon: faSquareB },
  { label: 'D', value: 'D', icon: faSquareD },
  { label: 'E', value: 'E', icon: faSquareE },
  { label: 'F', value: 'F', icon: faSquareF },
  { label: 'G', value: 'G', icon: faSquareG },
  { label: 'H', value: 'H', icon: faSquareH },
  { label: 'I', value: 'I', icon: faSquareI },
  { label: 'J', value: 'J', icon: faSquareJ },
  { label: 'K', value: 'K', icon: faSquareK },
  { label: 'L', value: 'L', icon: faSquareL },
  { label: 'M', value: 'M', icon: faSquareM },
  { label: 'N', value: 'N', icon: faSquareN },
  { label: 'O', value: 'O', icon: faSquareO },
  { label: 'P', value: 'P', icon: faSquareP },
  { label: 'R', value: 'R', icon: faSquareR },
  { label: 'S', value: 'S', icon: faSquareS },
  { label: 'T', value: 'T', icon: faSquareT },
  { label: 'U', value: 'U', icon: faSquareU },
  { label: 'V', value: 'V', icon: faSquareV },
  { label: 'Y', value: 'Y', icon: faSquareY },
  { label: 'Å', value: 'Å', icon: faSquareA },
] as const satisfies Options<Alphabet, { icon: IconDefinition }>

export const sortOrderSchema = picklist([
  'alfabetisk',
  'kronologisk',
])

export type SortOrder = InferOutput<typeof sortOrderSchema>

export const SORTING_OPTIONS = [
  {
    value: 'alfabetisk',
    label: 'Sorter alfabetisk',
    icon: faArrowUpAZ,
  },
  {
    value: 'kronologisk',
    label: 'Sorter kronologisk',
    icon: faArrowUp19,
  },
] as const satisfies Options<string, { icon: IconDefinition }>

export type SortingOption = typeof SORTING_OPTIONS[number]

export const viewModeSchema = picklist([
  'grid',
  'list',
])

export type ViewMode = InferOutput<typeof viewModeSchema>
