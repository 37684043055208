import type { ViewMode } from 'storybook/internal/types'
import type { Law } from '~/utils/solr/law'
import { SORTING_OPTIONS, type SortingOption } from '~/constants/law'
import { useLawQuery } from './useLawQuery'

/**
 * By defining values outside the composable function, we can ensure that the composable state is shared accross all components that use it.
 */

const NORWEGIAN_LOCALE = 'nb-NO'

const alphabetModel = ref()
const searchKeyword = ref<string>('')
const isFavorite = ref<boolean>(false)
const hasLawComment = ref<boolean>(false)
const sortOrderModel = ref<SortingOption>(SORTING_OPTIONS[0])
const viewMode = ref<ViewMode>('list')

export function useLawFilters() {
  const { data: solrData } = useLawQuery()

  const totalLaws = computed(() => solrData.value?.response.docs.length ?? 0)

  const hasFilters = computed(() => {
    return Boolean(alphabetModel.value || searchKeyword.value || isFavorite.value || hasLawComment.value || sortOrderModel.value?.value !== 'alfabetisk')
  })

  const sortedSolrLaws = computed<Law[]>(() => {
    if (!solrData.value?.response.docs) {
      return []
    }

    const docs = [...solrData.value.response.docs]

    return docs.sort((a, b) => {
      if (sortOrderModel.value?.value === 'kronologisk') {
        return (a.dateInForce || '').localeCompare(b.dateInForce || '', NORWEGIAN_LOCALE, { sensitivity: 'base' })
      }

      const titleA = a.shortTitle || a.title || ''
      const titleB = b.shortTitle || b.title || ''

      // Explicitly use Norwegian locale for sorting
      return titleA.localeCompare(titleB, NORWEGIAN_LOCALE, { sensitivity: 'base', ignorePunctuation: true })
    })
  })

  const groupedLaws = computed<Record<string, Law[]>>(() => {
    if (!sortedSolrLaws.value?.length) {
      return {}
    }

    return sortedSolrLaws.value.reduce((acc, law) => {
      const title = law.shortTitle || law.title || ''
      const firstLetter = title?.charAt(0).toUpperCase()
      acc[firstLetter] = acc[firstLetter] || []
      acc[firstLetter].push(law)
      return acc
    }, {} as Record<string, Law[]>)
  })

  const filteredLaws = computed<Record<string, Law[]>>(() => {
    if (!hasFilters.value) {
      return groupedLaws.value
    }

    return Object.entries(groupedLaws.value).reduce((acc, [key, laws]) => {
      const filteredLaws = laws.filter((law) => {
        const title = law.shortTitle || law.title || ''
        return (
          (!alphabetModel.value?.value || title.charAt(0).toUpperCase() === alphabetModel.value?.value)
          && (!searchKeyword.value || title.toLowerCase().includes(searchKeyword.value.toLowerCase()))
          && (!hasLawComment.value || law.has_commentary)
        // && (!isFavorite.value || law.is_favorite)
        )
      })

      if (filteredLaws.length) {
        acc[key] = filteredLaws
      }

      return acc
    }, {} as Record<string, Law[]>)
  })

  const totalFilteredLaws = computed(() => {
    return Object.values(filteredLaws.value).reduce((acc, laws) => acc + laws.length, 0)
  })

  function toggleFavorite() {
    isFavorite.value = !isFavorite.value
  }

  function toggleHasLawComment() {
    hasLawComment.value = !hasLawComment.value
  }

  function handleViewModeChange(mode: ViewMode) {
    viewMode.value = mode
  }

  return {
    viewMode,
    sortOrderModel,
    totalLaws,
    alphabetModel,
    isFavorite,
    hasLawComment,
    searchKeyword,
    hasFilters,
    toggleFavorite,
    toggleHasLawComment,
    filteredLaws,
    totalFilteredLaws,
    handleViewModeChange,
  }
}
