import defu from 'defu'
import { array, type InferOutput, intersect, object, parse } from 'valibot'
import { baseBodySchema } from '~/utils/solr/body'
import { headersSchema } from '~/utils/solr/headers'
import { lawSchema } from '~/utils/solr/law'

export const solrLawResponseSchema = object({
  responseHeader: headersSchema,
  response: intersect([
    baseBodySchema,
    object({
      docs: array(lawSchema),
    }),
  ]),
})

export type SolrLawResponseSchema = InferOutput<typeof solrLawResponseSchema>

function useLawQueryOptions() {
  const { user } = useUserSession()

  return {
    queryKey: [user.value?.userId, 'laws'],
    async queryFn() {
      const res = await $fetch('/api/solr/laws')
      return parse(solrLawResponseSchema, res)
    },
  }
}

type QueryOptions = ReturnType<typeof useLawQueryOptions>

export function useLawQuery(options?: Partial<QueryOptions>) {
  const initialOptions = useLawQueryOptions()

  const resolvedOptions = computed(() => defu<QueryOptions, [QueryOptions]>(options, initialOptions))

  return useQuery(resolvedOptions.value)
}
