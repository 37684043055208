<script setup lang="ts">
import { useLawFilters } from '~/composables/laws/useLawFilters'
import { ParamsKey } from '~/utils/ibexa/params'

useHead({
  bodyAttrs: {
    class: 'bg-surface-secondary',
  },
})

definePageMeta({
  layout: 'default',
  colorMode: 'light',
  theme: 'blurple',
  breadcrumb: {
    label: 'Lover og lovkommentarer',
  },
})

useSeoMeta({
  title: 'Lover og lovkommentarer',
  description: 'Finn lover og lovkommentarer',
})

const locationId = 5262
const { data: ibexaData } = await useIbexaContent({ [ParamsKey.LocationId]: locationId })

const title = computed<string | undefined>(() => ibexaData.value?.[0]?.title)

const offset = useHeaderHeight()

const { hasFilters, filteredLaws, viewMode } = useLawFilters()
</script>

<template>
  <Breadcrumbs />
  <ContentContainer>
    <section v-if="title" class="pt-m pb-xl">
      <div class="max-w-full md:px-2:px-0 3xl:px-[170px]">
        <h1 class="title-xl sm:title-xxl lg:text-hero max-w-md lg:max-w-xl" v-text="title" />
      </div>
    </section>

    <section id="law-container" class="flex flex-col bg-white rounded-4xl shadow-sm pb-4xl -mx-m px-m 2xl:px-0">
      <LawCommentaryFilter />
      <div class="w-full 2xl:max-3xl:px-m 3xl:max-w-screen-xl mx-auto">
        <LawCommentaryList v-if="viewMode === 'list'" :laws="filteredLaws" :has-filters="hasFilters" />
        <LawCommentaryGrid v-else :laws="filteredLaws" :has-filters="hasFilters" />
      </div>
    </section>
  </ContentContainer>
</template>

<style scoped lang="postcss">
#law-container > div:first-child {
  top: calc(v-bind(offset));
}

#sort,
#alphabet {
  :deep(.dropdown-outlined) {
    &:has(+ .dropdown-list li[aria-selected='true']) {
      @apply bg-blurple-500 text-white;

      > input {
        @apply placeholder:text-white !important;
      }

      svg {
        @apply text-white;
      }

      :deep(#dropdown-checkmark svg) {
        .fa {
          @apply opacity-0;
        }
      }
    }
  }

  :deep(.dropdown-list) {
    li[aria-selected='true'] {
      @apply bg-blurple-500 text-white;
    }
  }
}

#alphabet :deep(.dropdown-list) {
  > ul {
    @apply flex items-center flex-wrap gap-3xs py-s px-2xs;

    > li {
      @apply w-10 h-10 p-0 flex items-center justify-center bg-carbon-50 rounded-lg hover:bg-blurple-50;
    }
  }
}
</style>
