<script setup lang="ts">
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faArrowUpAZ, faBookmark, faBookSection, faGrid2, faListUl, faSquareA, faXmark } from '@fortawesome/pro-light-svg-icons'
import { useLawFilters } from '~/composables/laws/useLawFilters'
import { ALPHABET_OPTIONS, SORTING_OPTIONS } from '~/constants/law'

const {
  sortOrderModel,
  totalLaws,
  alphabetModel,
  searchKeyword,
  isFavorite,
  hasLawComment,
  toggleFavorite,
  toggleHasLawComment,
  totalFilteredLaws,
  handleViewModeChange,
  viewMode,
} = useLawFilters()

const alphabetDropdownIcon = computed<IconDefinition>(() => {
  if (!alphabetModel.value) {
    return faSquareA
  }

  return ALPHABET_OPTIONS.find(option => option.value === alphabetModel.value.value)?.icon || faSquareA
})

const sortDropdownIcon = computed<IconDefinition>(() => {
  if (!sortOrderModel.value) {
    return faArrowUpAZ
  }

  return SORTING_OPTIONS.find(option => option.value === sortOrderModel.value?.value)?.icon || faArrowUpAZ
})
</script>

<template>
  <div class="bg-white sticky rounded-4xl flex flex-col items-center gap-m pt-2xl pb-xl xl:max-3xl:px-0">
    <div class="w-full 2xl:max-3xl:px-m 3xl:max-w-screen-xl gap-s flex md:flex-wrap flex-nowrap items-start 3xl:items-center">
      <div class="flex w-full max-w-[420px] shrink-0">
        <FormSearchInput
          v-model="searchKeyword"
          resettable
          shape="square"
          size="medium"
          placeholder="Finn lov"
        />
      </div>
      <FormDropdown
        id="alphabet"
        v-model="alphabetModel"
        size="small"
        variant="outlined"
        :options="ALPHABET_OPTIONS"
        :icon-left="alphabetDropdownIcon"
        placeholder="Forbokstav"
        :option-icon="undefined"
        :option-id="option => option.value"
        :option-label="option => `${option.label}`"
        class="w-[200px]"
      />
      <Chip size="large" variant="outlined" aria-label="Med lovkommentarer" :icon-left="!hasLawComment ? faBookSection : faXmark" class="shrink-0" :class="{ 'bg-blurple-500 text-white': hasLawComment }" @click="toggleHasLawComment">
        Med lovkommentarer
      </Chip>
      <Chip size="large" variant="outlined" aria-label="Favoritter" :icon-left="!isFavorite ? faBookmark : faXmark" class="shrink-0" :class="{ 'bg-blurple-500 text-white': isFavorite }" @click="toggleFavorite">
        Favoritter
      </Chip>
      <FormDropdown
        id="sort"
        v-model="sortOrderModel"
        size="small"
        variant="outlined"
        :multiple="false"
        :options="SORTING_OPTIONS"
        :icon-left="sortDropdownIcon"
        placeholder="Sorter alfabetisk"
        :option-icon="option => option.icon"
        :option-id="option => option.value"
        :option-label="option => `${option.label}`"
        :deselectable="() => false"
        class="w-[229px] flex"
      />
    </div>
    <div class="flex w-full 2xl:max-3xl:px-m 3xl:max-w-screen-xl items-center">
      <p class="grow font-bold shrink-0 text-small">
        Viser {{ totalFilteredLaws }} av {{ totalLaws }} lover
        <span v-if="sortOrderModel?.value === 'kronologisk'" class="text-caption font-normal">(sortert etter ikrafttredelsesdato)</span>
      </p>
      <div class="flex flex-end gap-2xs">
        <Chip size="large" variant="neutral" color="blurple" aria-label="Vis som liste" :icon-left="faListUl" :class="{ 'bg-surface-primary': viewMode === 'list' }" @click="() => handleViewModeChange('list')">
          Vis som liste
        </Chip>
        <Chip size="large" variant="neutral" color="blurple" aria-label="Vis som grid" :icon-left="faGrid2" :class="{ 'bg-surface-primary': viewMode === 'grid' }" @click="() => handleViewModeChange('grid')">
          Vis som grid
        </Chip>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
#sort,
#alphabet {
  :deep(.dropdown-outlined) {
    &:has(+ .dropdown-list li[aria-selected='true']) {
      @apply bg-blurple-500 text-white;

      > input {
        @apply placeholder:text-white !important;
      }

      svg {
        @apply text-white;
      }

      :deep(#dropdown-checkmark svg) {
        .fa {
          @apply opacity-0;
        }
      }
    }
  }

  :deep(.dropdown-list) {
    li[aria-selected='true'] {
      @apply bg-blurple-500 text-white;
    }
  }
}

#alphabet :deep(.dropdown-list) {
  > ul {
    @apply flex items-center flex-wrap gap-3xs py-s px-2xs;

    > li {
      @apply w-10 h-10 p-0 flex items-center justify-center bg-carbon-50 rounded-lg hover:bg-blurple-50;
    }
  }
}
</style>
