<script setup lang="ts">
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { KsIcon } from '@aschehoug/kloss'
import { faTriangleExclamation as faDuoTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons'
import { faCircleInfo, faSparkles, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { badgeVariants } from '~/utils/badge'

const props = defineProps<{
  variant: BadgeIntent
  size: BadgeSize
  label?: string
}>()

const VARIANTS = {
  success: {
    icon: faSparkles,
  },
  warning: {
    icon: faTriangleExclamation,
  },
  danger: {
    icon: faDuoTriangleExclamation,
  },
  info: {
    icon: faCircleInfo,
  },
} as const satisfies Record<BadgeIntent, { icon?: IconDefinition }>

const v = computed(() => VARIANTS[props.variant])
</script>

<template>
  <div
    :class="[
      badgeVariants({
        intent: variant,
        size,
      }),
    ]"
    :aria-label="label"
  >
    <KsIcon v-if="size === 'large'" :icon="v.icon" :scale="0.8" />
    <span v-if="label && size !== 'small'">{{ label }}</span>
  </div>
</template>
